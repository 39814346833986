
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { FormControl, IconButton, InputLabel, MenuItem, Select, TablePagination, TextField, Tooltip } from '@mui/material'
import { Clear, Search as SearchIcon } from '@mui/icons-material'
import { creativeAssetStatusFilter } from '../../../../helpers/ProjectsHelper'
import { getProjectAssetsWithFilters } from '../../../../store/projects/actionCreator'
import PageSizeSelector from '../../../Search/PageSizeSelector'

const styles = makeStyles({
  projectFilters: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  projectPagination: {
    display: 'flex'
  },
  projectAssetFilters: {
    display: 'flex',
    justifyContent: 'start',
    gap: '10px',
    padding: '10px 0px 0px 10px',
  },
  searchField: {
    width: '220px',
  },
  dropdownField: {
    width: 180,
    height: 45,
  },
  searchText: {
    height: 28
  },
})

const AssetFilters = () => {
  const {
    projects: {
      projectAssetCount = 0,
      projectData: { project_uuid = '' } = {},
      pagination = {},
      filters = {},
    } = {},
  } = useSelector((state) => state) || {}
  const dispatch = useDispatch()
  const classes = styles()
  const [searchText, setSearchVal] = useState('')
  const [creativeAssetStatus, setCreativeAssetStatus] = useState('All')

  const {
    page = 0,
    size = 21,
  } = pagination

  const handleCreativeAssetStatus = (assetStatus = '') => {
    setCreativeAssetStatus(assetStatus)
    let filters = {}
    if (assetStatus !== 'All') {
      filters['creative_asset_status'] = assetStatus
    }
    if (searchText) {
      filters['asset_name'] = searchText
    }
    dispatch(getProjectAssetsWithFilters(project_uuid, pagination, filters))
  }

  const handlePagination = (event, page) => {
    const newPagination = { ...pagination, page }
    dispatch(getProjectAssetsWithFilters(project_uuid, newPagination, filters))
  }

  const onPageSizeChange = (event) => {
    const size = event.target.value
    const newPagination = { ...pagination, page: 0, size }
    dispatch(getProjectAssetsWithFilters(project_uuid, newPagination, filters))
  }

  const setFiltersObject = (searchTextValue = '') => {
    let filters = {}
    if (creativeAssetStatus !== 'All') {
      filters['creative_asset_status'] = creativeAssetStatus
    }
    if (searchText) {
      filters['asset_name'] = searchTextValue ?? searchText
    }
    return filters
  }

  const filterSearchAssets = (searchTextValue = '') => {
    const filters = setFiltersObject(searchTextValue)
    dispatch(getProjectAssetsWithFilters(project_uuid, pagination, filters))
  }

  return (
    <div className={classes.projectFilters}>
      <div className={classes.projectAssetFilters}>
        <FormControl variant="outlined">
          <TextField
            id="assets_search"
            name="assets_search"
            placeholder="Search by asset name"
            rows="1"
            value={searchText}
            onChange={(e) => {
              setSearchVal(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                filterSearchAssets(searchText)
              }
            }}
            variant="outlined"
            size={'small'}
            InputProps={{
              classes: { 'input': classes.searchText },
              endAdornment:
                searchText === '' ? null : (
                  <Tooltip title="Clear Filter">
                    <IconButton
                      onClick={() => {
                        setSearchVal('')
                        filterSearchAssets('')
                      }}
                      style={{ padding: 0 }}
                      size="small"
                    >
                      <Clear />
                    </IconButton>
                  </Tooltip>
                ),
            }}
            className={classes.searchField}
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select
            variant="outlined"
            name='creativeAssetStatus'
            value={creativeAssetStatus}
            onChange={(event) => handleCreativeAssetStatus(event.target.value)}
            label="Status"
            className={classes.dropdownField}
          >
            {creativeAssetStatusFilter.map((dropDownItems, index) => {
              const { value = 'All', label = 'All' } = dropDownItems
              return (
                <MenuItem value={value} key={index}>
                  {label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      <div className={classes.projectPagination}>
        <PageSizeSelector
          pageSize={size}
          onPageSizeChange={onPageSizeChange}
        />
        <TablePagination
          data-cy="tablePagination"
          component="div"
          testid="tablePagination"
          labelRowsPerPage={'Assets per page:'}
          count={projectAssetCount}
          page={page}
          rowsPerPage={size}
          rowsPerPageOptions={[]}
          onPageChange={handlePagination}
        />
      </div>
    </div>
  )
}

export default AssetFilters;