import Firefly from 'firefly-sdk-ts'
import moment from 'moment-timezone'

import {
  generateSessionId,
  generateTaskId,
  initializeFirefly,
  resetTaskId,
  sessionId,
  taskId,
  trackSession,
} from './initializeFirefly'
import * as fireflyInternalAppConfig from '../config/fireflyConfig.json'
import { searchFilterKey } from './firefly'
import { recursiveToCamel } from '../betasearch/mappers/searchMapper'
import { DOWNLOAD_ORIGINAL_VERSION } from '../constants/search'

export const BetaAnalytics = {
  event: {
    SEARCH_TEXT: 'SEARCH_TEXT',
    REFRESH_SEARCH: 'REFRESH_SEARCH',
    REVERSE_IMAGE_SEARCH: 'REVERSE_IMAGE_SEARCH',
    ADD_FILTER: 'ADD_FILTER',
    REMOVE_FILTER: 'REMOVE_FILTER',
    REMOVE_ALL_FILTERS: 'REMOVE_ALL_FILTERS',
    FILTER_UPLOAD_DATE: 'UPLOAD_DATE',
    FILTER_ASSET_DESCRIPTION: 'ASSET_DESCRIPTION',
    FILTER_COLOR: 'COLOR',
    FILTER_IS_ARCHIVED: 'IS_ARCHIVED',
    FILTER_INCLUDE_ARCHIVED: 'INCLUDE_ARCHIVED',
    FILTER_FILE_TYPE: 'File Type',
    FILTER_CLASSIFICATION: 'Classification',
    FILTER_AI_CLASSIFICATION: 'AI Classification',
    FILTER_CHANNEL: 'Channel',
    FILTER_BRAND: 'Brand',
    FILTER_DEPARTMENT: 'Department',
    FILTER_CLASS: 'Class',
    TEMPLATE: 'TEMPLATE',
    SORT_PREFERENCE: 'SORT_PREFERENCE',
    PAGE_CHANGE: 'PAGE_CHANGE',
    ROWS_PER_PAGE_CHANGE: 'ROWS_PER_PAGE_CHANGE',
    ASSET_USAGE: 'ASSET_USAGE',
    AI_ENABLED: 'ENABLED_FOR_AI_TESTING',
    DOWNLOAD_METADATA: 'DOWNLOAD_METADATA',
    ADD_ASSETS_TO_BOARDS: 'ADD_ASSETS_TO_BOARDS',
    DELETE_ASSET: 'DELETE_ASSET',
    DOWNLOAD_ASSET: 'DOWNLOAD_ASSET',
    BULK_DOWNLOAD_ASSETS: 'BULK_DOWNLOAD_ASSETS',
    SINGLE_ASSET_DETAIL_PAGE: 'SINGLE_ASSET_DETAIL_PAGE',
    SINGLE_UPDATE_METADATA: 'SINGLE_UPDATE_METADATA',
    BULK_UPDATE_METADATA: 'BULK_UPDATE_METADATA',
    SEARCH_RESPONSE_INSIGHTS: 'SEARCH_RESPONSE_INSIGHTS',
    //archived/unarchived asset
    ARCHIVED_ASSET: 'ARCHIVED_ASSET',
    UNARCHIVED_ASSET: 'UNARCHIVED_ASSET',
  },
  SCHEMA_VERSION: '1.40.0',
}

const TIMESTAMP_ISO_1806 = 'YYYY-MM-DD HH:mm:ss Z'
const APPLICATION_BETA = 'assethub-ui_beta'
export const BETA_ANALYTICS_DELIMITER = ','
export let prevEventType = ''

export const getBetaApplication = (loginId = '') => {
  if (!sessionId) {
    generateSessionId(loginId)
  }
  return {
    name: APPLICATION_BETA,
    version: `${process.env.REACT_APP_VERSION}`,
    schemaVersion: BetaAnalytics.SCHEMA_VERSION,
    customSessionId: sessionId,
  }
}

const getCommonFireflyData = (auth = {}) => {
  const { lanId = '', email = '' } = auth
  const application = getBetaApplication(lanId)
  if (!taskId) {
    generateTaskId(lanId)
  }
  return {
    application,
    employee: { lanId },
    user: {
      customDimension1: email,
      id: lanId,
      segment: taskId,
    },
  }
}

const sendFireflyEvent = (
  authData = {},
  trackingData = {},
  fireflyGlobalData = {},
) => {
  const { lanId = '' } = authData
  const { eventType = '', eventName = {} } = trackingData
  let eventTimestamp = moment.utc().format(TIMESTAMP_ISO_1806)
  initializeFirefly()
  Firefly.setGlobalDataLayer(
    fireflyGlobalData,
    fireflyInternalAppConfig.default.persistMethod,
  )
  trackSession(lanId, eventTimestamp)
  const eventManager = Firefly.getEventManager(fireflyInternalAppConfig.default)
  eventManager.addEvent({
    event: {
      name: eventName,
      type: eventType,
      serverTimestamp: eventTimestamp,
    },
  })
  prevEventType = eventType
}

export const trackBetaSearchAnalytics = ({ auth = {}, trackingData = {} }) => {
  const { eventName = '' } = trackingData
  switch (eventName) {
    case BetaAnalytics.event.SEARCH_TEXT:
      trackSearchTextClick({
        auth,
        trackingData,
      })
      return
    case BetaAnalytics.event.FILTER_UPLOAD_DATE:
    case BetaAnalytics.event.FILTER_IS_ARCHIVED:
    case BetaAnalytics.event.FILTER_INCLUDE_ARCHIVED:
    case BetaAnalytics.event.FILTER_FILE_TYPE:
    case BetaAnalytics.event.FILTER_CLASSIFICATION:
    case BetaAnalytics.event.TEMPLATE:
    case BetaAnalytics.event.FILTER_CHANNEL:
    case BetaAnalytics.event.FILTER_BRAND:
    case BetaAnalytics.event.FILTER_DEPARTMENT:
    case BetaAnalytics.event.FILTER_CLASS:
    case BetaAnalytics.event.REMOVE_FILTER:
    case BetaAnalytics.event.REMOVE_ALL_FILTERS:
    case BetaAnalytics.event.ROWS_PER_PAGE_CHANGE:
    case BetaAnalytics.event.PAGE_CHANGE:
    case BetaAnalytics.event.SORT_PREFERENCE:
    case BetaAnalytics.event.AI_ENABLED:
    case BetaAnalytics.event.DOWNLOAD_METADATA:
      trackEventWithCustomInteraction({
        auth,
        trackingData,
      })
      return
    case BetaAnalytics.event.FILTER_ASSET_DESCRIPTION:
      trackAssetDescriptionFilter({
        auth,
        trackingData,
      })
      return
    case BetaAnalytics.event.FILTER_AI_CLASSIFICATION:
      trackAIClassificationFilter({
        auth,
        trackingData,
      })
      return
    case BetaAnalytics.event.FILTER_COLOR:
      trackColorFilter({
        auth,
        trackingData,
      })
      return
    case BetaAnalytics.event.REFRESH_SEARCH:
      trackRefreshSearch({
        auth,
        trackingData,
      })
      return
    case BetaAnalytics.event.REVERSE_IMAGE_SEARCH:
      trackReverseImageSearch({
        auth,
        trackingData,
      })
      return
    case BetaAnalytics.event.ADD_ASSETS_TO_BOARDS:
      trackAddAssetsToBoard({
        auth,
        trackingData,
      })
      return
    case BetaAnalytics.event.DELETE_ASSET:
      trackAssetDelete({
        auth,
        trackingData,
      })
      return
    case BetaAnalytics.event.DOWNLOAD_ASSET:
      trackAssetDownload({
        auth,
        trackingData,
      })
      return
    case BetaAnalytics.event.ARCHIVED_ASSET:
    case BetaAnalytics.event.UNARCHIVED_ASSET:
      trackAssetArchived({
        auth,
        trackingData,
      })
      return
    default:
      trackEventWithCustomInteraction({
        auth,
        trackingData,
      })
  }
}

export const trackSearchTextClick = ({ auth = {}, trackingData = {} }) => {
  const { eventData = {} } = trackingData
  const { searchTerm, searchType } = eventData
  const customInteraction = {
    key: searchType === 'ALL' ? searchFilterKey(searchTerm) : searchType,
    value: searchTerm,
  }
  const fireflyCommonData = getCommonFireflyData(auth)
  const fireflyGlobalData = { ...fireflyCommonData, customInteraction }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
}

export const trackEventWithCustomInteraction = ({
  auth = {},
  trackingData = {},
}) => {
  const { eventData = {} } = trackingData
  const { key, value } = eventData
  const fireflyCommonData = getCommonFireflyData(auth)
  const customInteraction = {
    key,
    value,
  }
  const fireflyGlobalData = { ...fireflyCommonData, customInteraction }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
}

export const trackRefreshSearch = ({ auth = {}, trackingData = {} }) => {
  const { eventData = {} } = trackingData
  const fireflyCommonData = getCommonFireflyData(auth)
  const customInteraction = {
    key: 'EXISTING_SEARCH_PAYLOAD',
    value: JSON.stringify(eventData),
  }
  const fireflyGlobalData = { ...fireflyCommonData, customInteraction }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
}

export const trackAssetDescriptionFilter = ({
  auth = {},
  trackingData = {},
}) => {
  const { eventData = {} } = trackingData
  const fireflyCommonData = getCommonFireflyData(auth)
  let customMetrics = {}
  if (eventData['metadata.imageData.people']) {
    customMetrics['metric1'] = eventData['metadata.imageData.people']
  }
  if (eventData['metadata.imageData.withFood']) {
    customMetrics['metric2'] = eventData['metadata.imageData.withFood']
  }
  if (eventData['metadata.imageData.animals']) {
    customMetrics['metric3'] = eventData['metadata.imageData.animals']
  }

  const fireflyGlobalData = { ...fireflyCommonData, customMetrics }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
}

export const trackAIClassificationFilter = ({
  auth = {},
  trackingData = {},
}) => {
  const { eventData = {} } = trackingData
  const fireflyCommonData = getCommonFireflyData(auth)
  let customMetrics = {}
  customMetrics['metric1'] = eventData.classifications.join(
    BETA_ANALYTICS_DELIMITER,
  )
  customMetrics['metric2'] = eventData.isTalentFilter
  const fireflyGlobalData = { ...fireflyCommonData, customMetrics }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
}

export const trackColorFilter = ({ auth = {}, trackingData = {} }) => {
  const { eventData = {} } = trackingData
  const fireflyCommonData = getCommonFireflyData(auth)
  let customMetrics = {}
  if (eventData['metadata.imageData.colorBuckets.bucket.nested']) {
    customMetrics['metric1'] =
      eventData['metadata.imageData.colorBuckets.bucket.nested']
  }
  if (eventData['metadata.imageData.colors.hex.nested']) {
    customMetrics['metric2'] = eventData['metadata.imageData.colors.hex.nested']
  }
  if (eventData['metadata.imageData.colors.hsv.nested']) {
    customMetrics['metric3'] = eventData['metadata.imageData.colors.hsv.nested']
  }

  const fireflyGlobalData = { ...fireflyCommonData, customMetrics }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
}

export const trackReverseImageSearch = ({ auth = {}, trackingData = {} }) => {
  const { eventData = {} } = trackingData

  const fireflyCommonData = getCommonFireflyData(auth)

  let customMetrics = {}
  customMetrics['metric1'] = eventData.fileName
  customMetrics['metric2'] = eventData.fileSize
  customMetrics['metric3'] = eventData.fileType

  const fireflyGlobalData = { ...fireflyCommonData, customMetrics }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
}

const trackAddAssetsToBoard = ({ auth = {}, trackingData = {} }) => {
  const { eventData = {} } = trackingData
  const { payload = {} } = eventData
  const fireflyCommonData = getCommonFireflyData(auth)

  Object.values(payload)?.flat(1).length &&
    Object.values(payload)
      .flat(1)
      .forEach((assetDetails) => {
        const customInteraction = {
          key: 'board_id',
          value: Object.keys(payload)?.join(BETA_ANALYTICS_DELIMITER),
        }
        let customMetrics = {}
        customMetrics['metric1'] = assetDetails?.asset_id || ''
        customMetrics['metric2'] = assetDetails?.file_name || ''
        customMetrics['metric3'] = assetDetails?.asset_preview_path || ''
        const fireflyGlobalData = {
          ...fireflyCommonData,
          customMetrics,
          customInteraction,
        }
        sendFireflyEvent(auth, trackingData, fireflyGlobalData)
      })
}

const trackAssetDelete = ({ auth = {}, trackingData = {} }) => {
  const { eventData = {} } = trackingData
  const { payload = {} } = eventData
  const fireflyCommonData = getCommonFireflyData(auth)
  let customMetrics = {}
  const { asset_uoi_ids = [], asset_master_uoi_ids = [] } = payload
  if (asset_uoi_ids.length) {
    customMetrics['metric1'] = asset_uoi_ids.join(BETA_ANALYTICS_DELIMITER)
  }
  if (asset_master_uoi_ids.length) {
    customMetrics['metric2'] = asset_master_uoi_ids.join(
      BETA_ANALYTICS_DELIMITER,
    )
  }
  const fireflyGlobalData = {
    ...fireflyCommonData,
    customMetrics,
  }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
}

const trackAssetArchived = ({ auth = {}, trackingData = {} }) => {
  const { eventData = {} } = trackingData
  const { payload = {} } = eventData
  const fireflyCommonData = getCommonFireflyData(auth)
  let customMetrics = {}

  if (payload.length) {
    customMetrics['metric1'] = payload.join(BETA_ANALYTICS_DELIMITER)
  }
  const fireflyGlobalData = {
    ...fireflyCommonData,
    customMetrics,
  }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
}

const constructAssetDetails = (eventData = {}) => {
  const { asset = {}, downloadStyle = '' } = eventData
  if (downloadStyle === BetaAnalytics.event.SINGLE_ASSET_DETAIL_PAGE) {
    const assetData = recursiveToCamel(asset)
    assetData['fileName'] = assetData?.metadata?.fileMetadata?.fileName || ''
    assetData['projectInfo'] = assetData?.metadata?.projectInfo || {}
    assetData['itemInfo'] = assetData?.metadata?.itemInfo || []
    assetData['usageInfo'] = assetData?.metadata?.usageInfo || []
    assetData['assetClassification'] =
      assetData.metadata?.assetClassification || {}
    return assetData
  } else {
    return asset
  }
}

const trackAssetDownload = ({ auth = {}, trackingData = {} }) => {
  const { eventData = {} } = trackingData
  const { asset = {}, downloadResolution = '', downloadStyle = '' } = eventData
  const assetDetails = constructAssetDetails(eventData)
  const {
    assetId = '',
    fileName = '',
    isDerivative = '',
    masterChannel = '',
    projectInfo = {},
    itemInfo = [],
    usageInfo = [],
    assetClassification = {},
  } = assetDetails

  let itemBrands = []
  let itemMerchandiseGroupNames = []

  itemInfo.forEach((itemDetails) => {
    const { brand = '', merchandiseClassification = {} } = itemDetails
    const { groupName = '' } = merchandiseClassification || {}
    if (brand) {
      itemBrands.push(brand)
    }
    if (groupName) {
      itemMerchandiseGroupNames.push(groupName)
    }
  })
  const usageList = usageInfo?.map((usageDetails) => usageDetails.drmId) || []
  const fireflyCommonData = getCommonFireflyData(auth)
  fireflyCommonData.user['customDimension2'] = itemBrands.length
    ? itemBrands.join(BETA_ANALYTICS_DELIMITER)
    : ''
  fireflyCommonData.user['customDimension3'] = itemMerchandiseGroupNames.length
    ? itemMerchandiseGroupNames.join(BETA_ANALYTICS_DELIMITER)
    : ''
  fireflyCommonData.user['segment'] = usageList.length
    ? usageList.join(BETA_ANALYTICS_DELIMITER)
    : ''
  const customInteraction = {
    key: assetId,
    value: downloadStyle,
    location: fileName,
  }
  let customMetrics = {}
  customMetrics['metric1'] = projectInfo?.channel || ''
  customMetrics['metric2'] = masterChannel
  customMetrics['metric3'] = assetClassification?.classificationCode || ''
  customMetrics['metric4'] =
    downloadResolution === DOWNLOAD_ORIGINAL_VERSION ? 2 : 1
  const fireflyGlobalData = {
    ...fireflyCommonData,
    customMetrics,
    customInteraction,
  }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
}

export const trackBetaSearchBulkDownloads = (betaFireflyData = {}) => {
  const { selectedAssets = [], auth = {}, downloadAssetType } = betaFireflyData
  selectedAssets.forEach((assetDetails) => {
    const fireflyTrackingData = {
      eventType: BetaAnalytics.event.ASSET_USAGE,
      eventName: BetaAnalytics.event.DOWNLOAD_ASSET,
      eventData: {
        asset: assetDetails,
        downloadResolution: downloadAssetType,
        downloadStyle: BetaAnalytics.event.BULK_DOWNLOAD_ASSETS,
      },
    }
    trackAssetDownload({ auth, trackingData: fireflyTrackingData })
  })
}

export const trackUpdateMetadata = ({
  authData,
  metadataPayload,
  trackingData,
}) => {
  const { asset_id = '', update_data = {} } = metadataPayload
  const {
    asset_descriptions = {},
    creative_usage_rights = {},
    drm_ids = {},
    tcins = {},
    classification_id = '',
    file_name = '',
    librarian_review_status = '',
    user_defined_brand_name = '',
  } = update_data
  const { keywords = [], shot_types = [], subject = [] } = asset_descriptions
  const {
    additional_directions = '',
    do_not_use = '',
    instructions = '',
  } = creative_usage_rights
  const { data: drmData = [] } = drm_ids
  const { data: tcinData = [] } = tcins
  const fireflyCommonData = getCommonFireflyData(authData)
  if (drmData.length) {
    fireflyCommonData.user['customDimension2'] =
      drmData.join(BETA_ANALYTICS_DELIMITER) || ''
  }
  if (tcinData.length) {
    fireflyCommonData.user['customDimension3'] =
      tcinData.join(BETA_ANALYTICS_DELIMITER) || ''
  }
  let customInteraction = {
    key: asset_id,
    value: librarian_review_status,
    location: file_name,
  }
  let customMetrics = {}
  if (classification_id) {
    customMetrics['metric1'] = classification_id
  }
  if (user_defined_brand_name) {
    customMetrics['metric2'] = user_defined_brand_name
  }
  if (do_not_use !== '') {
    customMetrics['metric3'] = do_not_use
  }
  if (keywords.length) {
    customMetrics['metric4'] = keywords.length
  }
  if (shot_types.length) {
    customMetrics['metric5'] = shot_types.length
  }
  if (subject.length) {
    customMetrics['metric6'] = subject.length
  }
  const fireflyGlobalData = {
    ...fireflyCommonData,
    customMetrics,
    customInteraction,
  }
  sendFireflyEvent(authData, trackingData, fireflyGlobalData)
}

export const trackBulkUpdateMetadata = ({
  authData = {},
  bulkMetadataPayload = [],
}) => {
  const trackingData = {
    eventType: BetaAnalytics.event.ASSET_USAGE,
    eventName: BetaAnalytics.event.BULK_UPDATE_METADATA,
  }
  bulkMetadataPayload.forEach((singleMetadata) => {
    trackUpdateMetadata({
      authData,
      metadataPayload: singleMetadata,
      trackingData,
    })
  })
}

export const trackSearchInsights = ({ auth, requestPayload, response }) => {
  const fireflyCommonData = getCommonFireflyData(auth)
  const trackingData = {
    eventType: prevEventType ?? BetaAnalytics.event.SEARCH_RESPONSE_INSIGHTS,
    eventName: BetaAnalytics.event.SEARCH_RESPONSE_INSIGHTS,
  }
  const { page = '', rowsPerPage = '', payload = {} } = requestPayload
  const {
    search_term = '',
    search_type = '',
    sort_fields = {},
    template_id = '',
    filters = {},
  } = payload
  let customMetrics = {}
  customMetrics['metric1'] = search_type
  customMetrics['metric2'] = search_term
  customMetrics['metric3'] = JSON.stringify(sort_fields)
  customMetrics['metric4'] = Number(page)
  customMetrics['metric5'] = Number(rowsPerPage)
  fireflyCommonData.user['customDimension2'] = template_id
  const customInteraction = {
    key: 'total_results',
    value: response?.total_results || Number(0),
    location: JSON.stringify(filters),
  }
  const fireflyGlobalData = {
    ...fireflyCommonData,
    customMetrics,
    customInteraction,
  }
  sendFireflyEvent(auth, trackingData, fireflyGlobalData)
  resetTaskId()
}
